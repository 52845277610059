<template>
  <div class="modal">
    <div class="wrapper">
      <div class="title" v-if="payload.title" v-html="payload.title"></div>
      <div
        class="content px-4"
        v-if="payload.content"
        v-html="payload.content"
      ></div>
      <div class="content px-4" v-if="payload.cardUrl">
        <img :src="payload.cardUrl" alt="" />
      </div>
      <div class="btn-block">
        <button v-if="!payload.fb && !payload.recheck" @click="$emit('close')">
          關閉
        </button>
        <button class="green" v-if="payload.recheck" @click="$emit('close')">
          關閉
        </button>
        <button class="green" v-if="payload.fb" @click="$emit('close')">
          我知道了
        </button>
        <button class="fb" v-if="payload.fb" @click="goFB">前往FB</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["payload"],
  methods: {
    goFB() {
      location.href = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  background-color: #5d5d5d80;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  height: 90vw;
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #4d4d4d;
  font-size: 24px;
  .title {
    padding-top: 10px;
    font-weight: 700;
  }
  .content {
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    ::v-deep span {
      color: #0071bc;
      font-weight: 600;
    }
  }
  button {
    font-size: 24px;
    color: #fff;
    background-color: #f15a24;
    padding: 7px 20px;
    border-radius: 20px;
    margin: 0 10px;
    &.fb {
      background-color: #0071bc;
      font-weight: 600;
    }
    &.green {
      background-color: #22b573;
    }
  }
}
</style>