export default {
  data() {
    return {
      modal: {
        show: false,
        title: "",
        content: "",
      },
    };
  },
  methods: {
    modalEvent(event) {
      switch (event) {
        case "birthError":
          this.modal = {
            show: true,
            // title: "你輸入的生日無效<br>或者格式不正確喔！",
            // content: "目前可查詢範圍1942-2022年請重新輸入。",
            cardUrl: this.$store.state.images["POP-A.png"],
          };
          break;
        case "birthErrorB":
          this.modal = {
            show: true,
            // title: "你輸入的生日無效<br>或者格式不正確喔！",
            // content: "目前可查詢範圍1942-2022年請重新輸入。",
            cardUrl: this.$store.state.images["POP-B.png"],
          };
          break;
        case "passDay":
          this.modal = {
            show: true,
            title: "無法再往前查囉。",
            content: "若你需要紀錄<br>請儘早截圖",
          };
          break;
        case "comeNext":
          this.modal = {
            show: true,
            title: "想看下個月份？",
            content: "每個月的15號會開放下個月<br>到時再來喔。",
          };
          break;
        case "recheck":
          this.modal = {
            show: true,
            recheck: true,
            content:
              "嗨！<br>你的預測數值<br>已完成二次鑑定，<br>可能會跟之前不太一樣，<br>去看看吧！",
          };
          break;
        case "fb":
          this.modal = {
            show: true,
            fb: true,
            content:
              "你好！<br>由於你的生日特殊，<br>建議你前往FB粉絲專頁<br>並私訊申請<br><span>「再次校準」</span>",
          };
          break;
      }
    },
  },
};
